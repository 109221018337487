// 在线报价
<template>
  <div class="order-create-body online-body">
    <div class="division">
      <span class="line">
      </span>
       <!-- 专车 1； 拼车 2 -->
      <span class="circle-box" :class="type == 1 ? 'active-type' : ''" @click="selectedType(1)">专车</span>
      <span class="circle-box" :class="type == 2 ? 'active-type' : ''" @click="selectedType(2)">拼车</span>
      <span class="line">
      </span>
    </div>
    <div style="display: flex;justify-content: center;">
      <div class="main-body">
        <div class="labelTile">第一步：填写订单信息</div>
        <div class="mainPanel">
          <div class="title"><i class="idx">1</i>订单信息</div>
          <div class="main-box">
            <el-form :model="dataForm" ref="dataForm" size="small" label-width="10px">
              <el-form-item :rules="[{ required: true }]">
                <div v-for="(item, index) in dataForm.addressInfoList" :key="item.id">
                  <!--提卸货地址基本信息-->
                  <el-row :gutter="50">
                    <el-col :span="24">
                      <el-row>
                        <el-form-item :rules="[{ required: true }]">
                          <el-col :span="5">
                            <el-button type="primary">提货方</el-button>
                          </el-col>
                          <el-col :span="17">
                            <el-cascader
                              v-model="item.cityList"
                              :options="$store.state.areaList"
                              ref="cascaderTake"
                              clearable
                              filterable
                              change-on-select
                              @clear="clearPrice"
                              @change="calculation()"
                              :props="{ checkStrictly: false, value : 'name', label : 'name' }"
                              placeholder="选择省-市-区"></el-cascader>
                          </el-col>
                        </el-form-item>
                      </el-row>
                    </el-col>
                  </el-row>
                </div>
                <el-row :gutter="50">
                  <el-col :span="24">
                    <el-row>
                      <el-form-item :rules="[{ required: true }]">
                        <el-col :span="5">
                          <el-button type="danger">卸货方</el-button>
                        </el-col>
                        <div v-for="(item, index) in dataForm.unLoadAddressInfoList" :key="item.id">
                          <el-col :span="10">
                            <el-cascader
                              v-model="item.cityList"
                              :options="$store.state.areaList"
                              ref="cascaderTake"
                              clearable
                              filterable
                              change-on-select
                              @clear="clearPrice"
                              @change="calculation()"
                              :props="{ checkStrictly: false, value : 'name', label : 'name' }"
                              placeholder="选择省-市-区"></el-cascader>
                          </el-col>
                        </div>
                        <el-col :span="7">
                          <el-select class="item-choose" v-model="dataForm.giveAddressInfo.factoryName" filterable clearable
                                     @clear="clearPrice"
                                     @change="selectGiveFactory()"
                                     placeholder="请选择卸货仓">
                            <el-option v-for="item in giveAreaList" :key="item.id"
                                       :label="item.simpleName" :value="item.id">
                            </el-option>
                          </el-select>
                        </el-col>
                      </el-form-item>
                    </el-row>
                  </el-col>
                </el-row>
                <el-row :gutter="50">
                  <el-col :span="24">
                    <el-row>
                      <el-form-item :rules="[{ required: true }]">
                        <el-col :span="5">
                          <span v-if="type == 2" style="color: red">*</span>货物件重体
                        </el-col>
                        <el-col :span="5">
                            <el-input size="mini" v-model="dataForm.goodsInfoList.pieceNum" placeholder="总件数" @blur="calculation()"></el-input>
                        </el-col>
                        <el-col :span="6">
                            <el-input size="mini" v-model="dataForm.goodsInfoList.weight" placeholder="总重量（kg）" @blur="calculation()"></el-input>
                        </el-col>
                        <el-col :span="6">
                            <el-input size="mini" v-model="dataForm.goodsInfoList.volume" placeholder="总体积（CBM）" @blur="calculation()"></el-input>
                      </el-col>
                      </el-form-item>
                    </el-row>
                  </el-col>
                </el-row>
                <el-row :gutter="50">
                  <el-col :span="24">
                    <el-row>
                      <el-form-item :rules="[{ required: true }]">
                        <el-col :span="5">
                          货物长宽高
                        </el-col>
                        <el-col :span="5">
                            <el-input size="mini" v-model="dataForm.goodsInfoList.length" placeholder="最长（CM）"></el-input>
                        </el-col>
                        <el-col :span="6">
                            <el-input size="mini" v-model="dataForm.goodsInfoList.width" placeholder="最宽（CM）"></el-input>
                        </el-col>
                        <el-col :span="6">
                            <el-input size="mini" v-model="dataForm.goodsInfoList.height" placeholder="最高（CM）"></el-input>
                      </el-col>
                      </el-form-item>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-form>
          </div>
          <div class="title" v-if="type == 1" style="margin-top: 0px"><i class="idx">2</i>订单车型</div>
          <div class="order-vehicle-layout" v-if="type == 1">
            <div v-for="(item, idx) in vehicleTypeList" :key="item.id" @click="showTips(item, idx)"
              :class="isactive == idx ? 'carBox activeBox' : 'carBox'">
              <div class="car-item">
                <icon-svg name="che" class="car-type"></icon-svg>
                <span class="car-name">{{ item.name }}</span>
              </div>
            </div>
            <div class="car-info" v-if="vehicleRulesTips">
              <div class="vehicle-rule">
                <label class="rule">车辆规则说明：</label>
                <p>{{ vehicleRulesTips }}</p>
              </div>
              <div class="vehicle-tip" v-if="vehicleTips">
                <label class="tip">温馨提示：</label>
                <p>{{ vehicleTips }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-body-2">
          <div class="pricePanel">
            <div class="labelTile">第二步：自动在线报价</div>
            <div class="title"><i class="idx">3</i>费用信息</div>
            <div class="fee-info">
              <p><span>运费：</span><span><strong>{{price}}</strong>元</span></p>
              <!-- <p><span>加点费：</span><span><strong>{{addFee}}</strong>元</span></p> -->
              <hr style="border-color: #17B3A3;" />
              <p style="font-size: 18px"><span>总费用：</span><span><strong style="color: #17B3A3">{{addFee + price}}</strong>元</span></p>
            </div>
            <div class="remark-explain">
              <span>
                <p class="title-word">备注说明：</p>
                <p>货物信息与车型信息无变化，则当前报价为最终运费；</p>
                <p>如订单信息有变动，导致价格变动，客服人员将再派前与您确认！</p>
              </span>
              <span>
                <p class="title-word">报价说明：</p>
                <p>1，专车车型地址不变的情况下，订单按此价格计费。</p>
                <p>2，拼车体积地址不变的情况下，订单按次价格计费。</p>
                <p>3，4.2米以上车型都可免费提供尾板。</p>
                <p>4，不含入仓费等代垫费，代垫费用实报实销。</p>
                <p>5，拼车价格，超重货物按照1:500计算。</p>
                <p>6，机场收获和送货，保税区提货各车型加收100元/车次。</p>
              </span>
            </div>
        </div>
      </div>
    </div>
    <explain-pop ref="explainPop"></explain-pop>
    <address-book-pop ref="addressBook"></address-book-pop>
  </div>
</template>

<script>
import explainPop from '@/views/commonPop/explain-popup.vue'
import addressBookPop from '@/views/commonPop/addressBook-popup.vue'

export default {
  components: {
    explainPop,
    addressBookPop
  },
  data () {
    return {
      dataForm: {
        vehicleType: null,
        goodsInfoList: {
          pieceNum: null,
          weight: null,
          volume: null,
          length: null,
          width: null,
          height: null
        },
        giveAddressInfo: { // 卸货地址信息
          factoryName: ''
        },
        addressInfoList: [{
          cityList: ''
        }], // 提货地址信息
        unLoadAddressInfoList: [{
          cityList: ''
        }] // 卸货地址信息
      },
      isactive: -1,
      vehicleTips: null,
      vehicleTypeList: [],
      giveFactoryNameList: [],
      vehicleRulesTips: null,
      addFee: 0,
      price: 0,
      totalPrice: 0,
      type: 1,
      giveAreaList: []
    }
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    }
  },
  activated () {
    this.getVehicleType()
    this.getGiveSelect()
    this.getTakeGiveAreaList()
  },
  methods: {
    clearPrice () {
      this.price = 0
    },
    // 选择卸货仓
    selectGiveFactory () {
      this.giveAreaList.forEach(item => {
        if (item.id === this.dataForm.giveAddressInfo.factoryName) {
          this.dataForm.unLoadAddressInfoList[0].cityList = this.getCityList(item)
          this.dataForm.giveAddressInfo.factoryName = item.simpleName
        }
      })
      this.calculation()
    },
    calculation () {
      // 当这些值都有时调用计算方法
      if (!!this.dataForm.addressInfoList[0].cityList && (!!this.dataForm.giveAddressInfo.factoryName || !!this.dataForm.unLoadAddressInfoList[0].cityList)) {
        //  专车 1； 拼车 2
        if (this.type == 1 && !this.dataForm.vehicleType) {
          return
        }
        if (this.type == 2 && (!this.dataForm.goodsInfoList.pieceNum || !this.dataForm.goodsInfoList.weight || !this.dataForm.goodsInfoList.volume)) {
          return
        }
        let addressList = []
        for (let i = 0; i < this.dataForm.addressInfoList.length; i++) {
          addressList.push({
            'province': this.dataForm.addressInfoList[i].cityList[0],
            'city': this.dataForm.addressInfoList[i].cityList[1],
            'area': this.dataForm.addressInfoList[i].cityList[2]
          })
        }
        let unLoadAddressInfoList = []
        for (let i = 0; i < this.dataForm.unLoadAddressInfoList.length; i++) {
          unLoadAddressInfoList.push({
            'province': this.dataForm.unLoadAddressInfoList[i].cityList[0],
            'city': this.dataForm.unLoadAddressInfoList[i].cityList[1],
            'area': this.dataForm.unLoadAddressInfoList[i].cityList[2],
            'factoryName': this.dataForm.giveAddressInfo.factoryName
          })
        }
        let formData = {}
        if (this.type == 1) {
          formData = {
            'orderType': this.type,
            'giveAddressInfo': {
              'street': this.dataForm.giveAddressInfo.factoryName
            },
            'vehicleType': this.dataForm.vehicleType,
            'addressList': addressList,
            'unLoadAddressInfoList': unLoadAddressInfoList
          }
        } else {
          formData = {
            'orderType': this.type,
            'giveAddressInfo': {
              'street': this.dataForm.giveAddressInfo.factoryName
            },
            'addressList': addressList,
            'unLoadAddressInfoList': unLoadAddressInfoList
          }
        }
        this.$http({
          url: this.$http.adornUrl(`/priceTemplateBaseCost/onlineQuotation`),
          method: 'post',
          data: this.$http.adornData(formData)
        }).then(({ data }) => {
          if (data && data.price) {
            this.price = data.price
            // this.addFee = (this.dataForm.addressInfoList.length - 1) * data.addFee || 0
          } else {
            this.price = 0
            // this.addFee = 0
          }
        })
      }
    },
    // 选择类型
    selectedType (val) {
      this.type = val
      this.price = 0
      this.isactive = -1
      this.vehicleRulesTips = null
      this.dataForm.vehicleType = null
      this.dataForm.goodsInfoList = {
        pieceNum: null,
        weight: null,
        volume: null,
        length: null,
        width: null,
        height: null
      }
      this.dataForm.giveAddressInfo = { // 卸货地址信息
        factoryName: ''
      }
      this.dataForm.addressInfoList = [{
        cityList: []
      }] // 提货地址信息
      // 获取卸货点
      this.getGiveSelect()
    },
    // 说明弹框
    explain (flag) {
      this.$refs.explainPop.init(flag)
    },
    // 获取车型信息
    getVehicleType () {
      this.$http({
        url: this.$http.adornUrl(`/vehicleType/findAll`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.vehicleTypeList = data
        if (this.dataForm.vehicleType) {
          data.forEach((item, index) => {
            if (item.id == this.dataForm.vehicleType) {
              this.showTips(item, index)
            }
          })
        }
        // this.showTips(data, index)
      })
    },
    // 点击车型显示提示信息
    showTips (item, index) {
      this.dataForm.vehicleType = item.id
      this.vehicleRulesTips = item.rule
      this.vehicleTips = item.tips
      this.isactive = index
      this.calculation()
    },
    getGiveSelect () {
      this.giveFactoryNameList = []
      this.$http({
        url: this.$http.adornUrl(`/priceBase/findGiveAddressList`),
        method: 'get',
        params: this.$http.adornParams({
          orderType: this.type
        })
      }).then(({ data }) => {
        this.giveFactoryNameList = data
      })
    },
    getTakeGiveAreaList () {
      this.$http({
        url: this.$http.adornUrl(`/customerAddress/findPublicGiveAddress`),
        method: 'get'
      }).then(({ data }) => {
        if (data) {
          this.giveAreaList = []
          data.forEach(item => {
            this.giveAreaList.push(item)
          })
        }
      })
    },
    checkAddressInfoList () {
      this.dataForm.waybillInfoList = this.dataForm.addressInfoList
      return true
    },
    getCityList (data) {
      let cityList = []
      if (data.province) {
        cityList.push(data.province)
      }
      if (data.city) {
        cityList.push(data.city)
      }
      if (data.area) {
        cityList.push(data.area)
      }
      return cityList
    }
  }
}
</script>

<style lang="less" scoped>
.online-body {
  .el-form-item__content {
    margin-left: 10px !important
  }
  .division {
    height: 60px;
    line-height: 60px;
    text-align: center;
    margin-bottom: 28px;
    .circle-box {
      width: 60px;
      height: 60px;
      display: inline-block;
      text-align: center;
      border-radius: 50%;
      margin: 0 20px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
    .active-type {
      background: #17B3A3;
      color: #fff
    }
    .line {
      display: inline-block;
      width: 120px;
      background: #17B3A3;
      height: 2px;
    }
  }
  .main-body,.main-body-2 {
    height: 69vh;
    float: left;
    width: 40%;
    position: relative;
    .main-box {
        margin-left: 30px;
    }
    .order-vehicle-layout {
        margin: 0 30px;
    }
    .labelTile {
      background: #fff;
      padding: 0 10px;
      position: absolute;
      height: 38px;
      line-height: 38px;
      text-align: center;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 1px solid #17B3A3;
      border-radius: 5px;
      z-index: 1;
    }
  }
  .mainPanel, .pricePanel {
    border: 1px solid #17B3A3;
    float: left;
    width: 100%;
    border-radius: 5px;
    // height: 70vh;
    position: relative;
    .labelBtn {
      margin-left: -84px;
      font-size: 12px;
      text-align: center;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
    }
    .title {
      height: 38px;
      line-height: 38px;
      padding-left: 15px;
      margin-top: 10px;
      i.idx {
        display: inline-block;
        background: #17B3A3;
        height: 20px;
        line-height: 20px;
        width: 20px;
        text-align: center;
        color: #fff;
        margin-right: 6px;
      }
    }
  }
  .main-body {
    // width: 65%;
    .mainPanel {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      .carBox {
        display: inline-block;
        width: 25%;
        text-align: center;
        height: 60px;
        margin: 5px auto;
        .car-item {
          width: 80%;
          margin-left: 10%;
          height: 100%;
          margin-top: 0;
          padding-top: 5px;
          cursor: pointer;
          box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 3px, rgba(0, 0, 0, 0.08) 0px 0px 3px;
          .car-type {
            width: 30px;
            height: 30px;
            display: block;
            margin: auto;
            margin-bottom: 5px;
            color: #777;
          }
          .car-name {
              font-size: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 100%;
              display: block;
          }
        }
      }
      .activeBox {
        .car-item {
            box-shadow: #45c2b5 0px 2px 4px, #45c2b5 0px 0px 6px;
            color: #45c2b5;
            .car-type {
              color: #45c2b5;
            }
        }
      }
      .order-vehicle-layout {
        .car-info {
            margin: 5px 8px;
            box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 3px, rgba(0, 0, 0, 0.08) 0px 0px 3px;
            padding: 8px;
            font-size: 12px;
            label {
              font-weight: 600;
              font-size: 14px;
            }
            .vehicle-tip {
              margin-top: 8px;
            }
            p {
              margin: 5px 0;
              text-indent: 2em;
            }
        }
      }
    }
  }
  .main-body-2 {
    .pricePanel {
      width: 100%;
      height: 100%;
      margin-left: 2%;
      .fee-info {
        padding: 10px;
        p {
          display: flex;
          justify-content: space-between;
          margin: 10px;
        }
      }
      .remark-explain {
        padding: 0 10px;
        position: absolute;
        bottom: 30px;
        font-size: 12px;
        p.title-word {
          height: 28px;
          line-height: 28px;
          font-weight: 800;
          margin: 0;
        }
        p {
          margin: 5px 0
        }
      }
    }
  }
}
</style>
